import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Eta = (props) => {
  const {
    eta
  } = props;
  const { t, i18n } = useTranslation();

  if (!eta) {
    return <React.Fragment/>;
  }

  let time;
  const date = new Date(eta);
  // If date.getTime is Nan it's an invalid date
  if (isNaN(date.getTime())) {
    time = eta;
  } else {
    time = date.toLocaleString(i18n.language, { hour: '2-digit', minute: '2-digit' });
  }

  return (
    <h1 className='eta col-xs-5 col-lg-12 col-sm-12 col-md-12'>{t('eta.estimated', { time })}</h1>
  );
};

Eta.propTypes = {
  eta: PropTypes.string
};

export default Eta;
